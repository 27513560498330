
import { css } from 'lit';
export default css`
/* Apply standardized box sizing to the component. */
:host {
  box-sizing: border-box;
}
:host *,
:host *::before,
:host *::after {
  box-sizing: inherit;
}
[hidden] {
  display: none !important;
}
/* Apply component specific CSS */
:host {
  display: flex;
  height: var(--spacing-136);
  width: 100vw;
}

#map {
  height: 100%;
  width: 100%;
}

/* Overwriting Google Maps button inline styles. */

.gm-style-iw button {
  top: 0px !important;
  right: -1px !important;
  width: 44px !important;
  height: 44px !important;
}

/* Overwriting Google Maps button close icon inline styles. */

.gm-style-iw button span {
  background-color: var(--neutral-black);
  width: 44px !important;
  height: 44px !important;
  padding: 4px !important;
  margin: 0 !important;
}

/* Overwriting Google Maps info window inline styles. */

.gm-style-iw {
  width: calc(100vw - 32px) !important;
  min-width: unset !important;
  max-width: calc(35.5rem - 32px) !important;
  margin: 0 !important;
}

.gm-style-iw-c {
  padding: 0 !important;
}

.gm-style-iw-d {
  overflow: auto !important;
}

a:focus,
a:focus-visible {
  outline: 1px solid var(--ui-accent);
  border-radius: 0;
}
`;