
import { css } from 'lit';
export default css`
/* Apply standardized box sizing to the component. */
:host {
  box-sizing: border-box;
}
:host *,
:host *::before,
:host *::after {
  box-sizing: inherit;
}
[hidden] {
  display: none !important;
}
/* Apply component specific CSS */
#overlay {
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  z-index: 50;
  display: flex;
  align-items: center;
  justify-content: center;
  --tw-bg-opacity: 0.6;

  /* Tailwind background opacity with customizations wasn't working.\`bg-opacity-60 bg-neutral-black\` */
  background-color: rgb(0, 0, 0, var(--tw-bg-opacity));
}

#container {
  height: 100vh;
  max-width: 100%;
  overflow-y: auto;
  border-radius: 0.125rem;
  background-color: var(--neutral-white);
}

@media (min-width: 768px) {

  #container {
    height: auto;
  }

  #container {
    max-width: 25vw;
  }
}

#container {
  min-width: 320px;
}

@media (min-width: 768px) {

  #overlay.medium #container {
    max-width: 50vw;
  }
}

#overlay.full-screen #container {
  height: 100vh;
}

#overlay.full-screen #container {
  width: 100vw;
}

#overlay.full-screen #container {
  max-width: 100vw;
}

@media (min-width: 768px) {

  #overlay.full-screen #container {
    width: 100vw;
  }
}

@media (min-width: 768px) {

  #overlay.full-screen #container {
    max-width: 100vw;
  }
}

#header {
  position: relative;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  padding-top: 1.5rem;
}

#close {
  position: absolute;
  top: 0.625rem;
  right: 0.625rem;
  border-width: 0px;
  background-color: var(--neutral-transparent);
}

/* We need a double slash on the unicode because of templates later. */

#close:before {
  content: '\\2715';
}

#main {
  padding: 1.5rem;
}

#accessibility-description {
  display: none;
}
`;