
import { css } from 'lit';
export default css`
/* Apply standardized box sizing to the component. */
:host {
  box-sizing: border-box;
}
:host *,
:host *::before,
:host *::after {
  box-sizing: inherit;
}
[hidden] {
  display: none !important;
}
/* Apply component specific CSS */
/* Devices with mouse support. */
@media (any-hover: hover) and (any-pointer: fine) {
  :host {
    cursor: pointer
  }
}
/* The default is information. */
#link {
  position: relative;
  left: 50%;
  right: 50%;
  margin-left: -50vw;
  margin-right: -50vw;
  display: flex;
  width: 100vw;
  border-width: 0px;
  border-left-width: 8px;
  border-style: solid;
  border-right-color: var(--brand-blueDark);
  background-color: var(--brand-blue);
  padding: 1rem;
  font-family: Hind, Helvetica, Arial, sans-serif;
  color: var(--neutral-white);
  text-decoration: none
}
#link:focus-within {
  --tw-shadow: 0 0 0 1px rgb(33, 74, 222);
  --tw-shadow-colored: 0 0 0 1px var(--tw-shadow-color);
  box-shadow: 0 0 #0000, 0 0 #0000, var(--tw-shadow);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
}
#link:focus {
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  box-shadow: 0 0 #0000, 0 0 #0000, var(--tw-shadow);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  outline: 2px solid transparent;
  outline-offset: 2px
}
.alert__link-text {
  font-weight: var(--fw-bold);
  text-decoration: underline
}
#header {
  display: flex;
  align-items: center;
  border-width: 0px;
  border-right-width: 1.5px;
  border-style: solid;
  border-right-color: var(--brand-blueDark);
  padding-right: 1rem;
  font-family: Montserrat, Helvetica, Arial, sans-serif;
  font-weight: var(--fw-bold);
  text-transform: capitalize;
  text-decoration: none
}
#message {
  padding-left: 1rem;
  font-family: Hind, Helvetica, Arial, sans-serif
}
:host([statusType='warning']) #link {
  border-color: var(--ui-warning)
}
:host([statusType='warning']) #link {
  background-color: var(--ui-warning)
}
:host([statusType='warning']) #link {
  color: var(--brand-blueDark)
}
:host([statusType='warning']) #header {
  border-right-color: var(--brand-goldDark)
}
:host([statusType='error']) #link {
  border-color: var(--ui-error)
}
:host([statusType='error']) #link {
  background-color: var(--ui-error)
}
:host([statusType='error']) #header {
  border-right-color: var(--ui-error-dark)
}
:host([statusType='success']) #link {
  border-color: var(--brand-greenDark)
}
:host([statusType='success']) #link {
  background-color: var(--brand-greenDark)
}
:host([statusType='success']) #header {
  border-right-color: var(--brand-greenDarker)
}
`;