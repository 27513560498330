
import { css } from 'lit';
export default css`
/* Apply standardized box sizing to the component. */
:host {
  box-sizing: border-box;
}
:host *,
:host *::before,
:host *::after {
  box-sizing: inherit;
}
[hidden] {
  display: none !important;
}
/* Apply component specific CSS */
:host {

    display: flex;

    height: 24rem;

    width: 100vw
}

/** 
 * Setting the map width and height is required. 
 * Essential to include with new styles when overwriting in an extended map component.
 **/

#map {

    height: 100%;

    width: 100%
}
`;