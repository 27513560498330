
import { css } from 'lit';
export default css`
/* Apply standardized box sizing to the component. */
:host {
  box-sizing: border-box;
}
:host *,
:host *::before,
:host *::after {
  box-sizing: inherit;
}
[hidden] {
  display: none !important;
}
/* Apply component specific CSS */
.accordion-title {
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
  font-family: Montserrat, Helvetica, Arial, sans-serif;
  font-size: var(--fs-3xl);
  line-height: var(--lh-3xl);
  font-weight: var(--fw-semibold);
  color: var(--brand-blueDark);
}

.accordion-title.mobile {
  font-size: var(--fs-3xl);
  line-height: var(--lh-3xl);
  color: var(--brand-blueDark);
}

.accordion-title:after {
  content: none;
}
`;