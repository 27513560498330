
import { css } from 'lit';
export default css`
/* Apply standardized box sizing to the component. */
:host {
  box-sizing: border-box;
}
:host *,
:host *::before,
:host *::after {
  box-sizing: inherit;
}
[hidden] {
  display: none !important;
}
/* Apply component specific CSS */
:host {
  position: sticky;
  top: 0px;
  z-index: 50;
  display: flex;
  width: 100%;
  flex-direction: column;
}

  :host header {
  margin-top: 0px;
  margin-bottom: 0px;
}

  :host header {
  margin-left: auto;
  margin-right: auto;
}

  :host header {
  display: flex;
}

  :host header {
  width: 100%;
}

  :host header {
  flex-direction: row;
}

  :host header {
  align-items: center;
}

  :host header {
  justify-content: space-between;
}

  :host header {
  border-width: 0px;
}

  :host header {
  border-style: solid;
}

  :host header {
  background-color: var(--neutral-white);
}

  :host header {
  padding-top: 0.5rem;
}

  :host header {
  --tw-shadow: 0px 5px 10px rgba(89, 89, 89, 0.15);
  --tw-shadow-colored: 0px 5px 10px var(--tw-shadow-color);
  box-shadow: 0 0 #0000, 0 0 #0000, var(--tw-shadow);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

  :host ::slotted(p) {
  border-width: 1px;
}

  :host ::slotted(p) {
  border-style: solid;
}

  :host ::slotted(p) {
  border-bottom-color: var(--brand-blueDark);
}

  :host ::slotted(p) {
  padding: 1rem;
}

  /*
   * Hamburger Menu base
   */

  :host .hamburger {
  margin: 0px;
}

  :host .hamburger {
  display: flex;
}

  :host .hamburger {
  cursor: pointer;
}

  :host .hamburger {
  flex-direction: column;
}

  :host .hamburger {
  align-items: center;
}

  :host .hamburger {
  border-width: 0px;
}

  :host .hamburger {
  background-color: var(--neutral-transparent);
}

  :host .hamburger {
  padding: 1rem;
}

  :host .hamburger:hover, :host .hamburger.is-open:hover {
  opacity: 0.7;
}

  :host .hamburger-inner, :host .hamburger-inner::before, :host .hamburger-inner::after {
  background-color: var(--brand-blue);
}

  :host .hamburger.is-open .hamburger-inner, :host .hamburger.is-open .hamburger-inner::before, :host .hamburger.is-open .hamburger-inner::after {
  background-color: var(--brand-blueLight);
}

  :host .hamburger-box {
  position: relative;
}

  :host .hamburger-box {
  display: inline-block;
}

  :host .hamburger-box {
  height: 1.25rem;
}

  :host .hamburger-box {
  width: var(--spacing-7_5);
}

  :host .hamburger-inner {
  top: 50%;
}

  :host .hamburger-inner {
  margin-top: -0.125rem;
}

  :host .hamburger-inner {
  display: block;
}

  :host .hamburger-inner, :host .hamburger-inner::before, :host .hamburger-inner::after {
  position: absolute;
}

  :host .hamburger-inner, :host .hamburger-inner::before, :host .hamburger-inner::after {
  height: var(--spacing-0_75);
}

  :host .hamburger-inner, :host .hamburger-inner::before, :host .hamburger-inner::after {
  width: var(--spacing-7_5);
}

  :host .hamburger-inner, :host .hamburger-inner::before, :host .hamburger-inner::after {
  transform: var(--tw-transform);
}

  :host .hamburger-inner, :host .hamburger-inner::before, :host .hamburger-inner::after {
  transition-duration: 150ms;
}

  :host .hamburger-inner, :host .hamburger-inner::before, :host .hamburger-inner::after {
  transition-timing-function: linear;
}

  :host .hamburger-inner::before, :host .hamburger-inner::after {
  display: block;
}

  :host .hamburger-inner::before,
  :host .hamburger-inner::after {
    content: '';
  }

  :host .header-text {
  margin-top: 0.25rem;
}

  :host .header-text {
  font-family: Montserrat, Helvetica, Arial, sans-serif;
}

  :host .header-text {
  font-size: var(--fs-sm);
  line-height: var(--lh-sm);
}

  :host .header-text {
  color: var(--brand-blue);
}

  :host .header.is-open .hamburger-text {
  color: var(--brand-blueLight);
}

  :host .header-text--chat {
  margin-top: 0px;
}

  /*
   * Hamburger Menu Slider animation
   */

  :host .hamburger--slider .hamburger-inner {
  top: 0.25rem;
}

  :host .hamburger--slider .hamburger-inner::before {
  transform: var(--tw-transform);
}

  :host .hamburger--slider .hamburger-inner::before {
  transition-property: opacity;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}

  :host .hamburger--slider .hamburger-inner::before {
  transition-duration: 150ms;
}

  :host .hamburger--slider .hamburger-inner::before {
  transition-timing-function: linear;
}

  :host .hamburger--slider .hamburger-inner::before {
    top: 0.4375rem;
  }

  :host .hamburger--slider .hamburger-inner::after {
    top: 0.875rem;
  }

  :host .hamburger--slider.is-open .hamburger-inner {
    transform: translate3d(0, 0.4375rem, 0) rotate(45deg);
  }

  :host .hamburger--slider.is-open .hamburger-inner::before {
  opacity: 0;
}

  :host .hamburger--slider.is-open .hamburger-inner::before {
    transform: translate3d(0.328rem, -0.3125rem, 0) rotate(-45deg);
  }

  :host .hamburger--slider.is-open .hamburger-inner::after {
    transform: translate3d(0, -0.875rem, 0) rotate(-90deg);
  }

  :host .chat {
  margin: 0px;
}

  :host .chat {
  display: flex;
}

  :host .chat {
  cursor: pointer;
}

  :host .chat {
  flex-direction: column;
}

  :host .chat {
  align-items: center;
}

  :host .chat {
  border-width: 0px;
}

  :host .chat {
  background-color: var(--neutral-transparent);
}

  :host .chat {
  padding: 1rem;
}

  :host .chat-slot {
  position: absolute;
}

  :host .chat-slot {
  display: block;
}

  :host .header-logo {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

  :host .hidden-text {
    clip: rect(1px, 1px, 1px, 1px);
    -webkit-clip-path: inset(50%);
            clip-path: inset(50%);
    height: 1px;
    width: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
  }
`;