
import { css } from 'lit';
export default css`
/* Apply standardized box sizing to the component. */
:host {
  box-sizing: border-box;
}
:host *,
:host *::before,
:host *::after {
  box-sizing: inherit;
}
[hidden] {
  display: none !important;
}
/* Apply component specific CSS */
:host {

    display: block
}

h1, h2, h3, h4, h5, h6 {

    margin: 0px;

    margin-bottom: 1rem
}

/* Moved to top to allow easier override */

.outline-text, .outline-text--base {

    font-size: var(--fs-base);

    line-height: var(--lh-base)
}

.outline-text--xs {

    font-size: var(--fs-xs);

    line-height: var(--lh-xs)
}

.outline-text--sm {

    font-size: var(--fs-sm);

    line-height: var(--lh-sm)
}

.outline-text--lg {

    font-size: var(--fs-lg);

    line-height: var(--lh-lg)
}

.outline-text--xl {

    font-size: var(--fs-xl);

    line-height: var(--lh-xl)
}

.outline-text--2xl {

    font-size: var(--fs-2xl);

    line-height: var(--lh-2xl)
}

.outline-text--3xl {

    font-size: var(--fs-3xl);

    line-height: var(--lh-3xl)
}

.outline-text--4xl {

    font-size: var(--fs-4xl);

    line-height: var(--lh-4xl)
}

.outline-text--5xl {

    font-size: var(--fs-5xl);

    line-height: var(--lh-5xl)
}

.outline-text--6xl {

    font-size: var(--fs-6xl);

    line-height: var(--lh-6xl)
}

.outline-text--7xl {

    font-size: var(--fs-7xl);

    line-height: var(--lh-7xl)
}

.outline-text--8xl {

    font-size: var(--fs-8xl);

    line-height: var(--lh-8xl)
}

.outline-text--9xl {

    font-size: var(--fs-9xl);

    line-height: var(--lh-9lx)
}

.outline-font--thin {

    font-weight: var(--fw-extralight)
}

.outline-font--extralight {

    font-weight: var(--fw-thin)
}

.outline-font--light {

    font-weight: var(--fw-light)
}

.outline-font--normal {

    font-weight: var(--fw-normal)
}

.outline-font--semibold {

    font-weight: var(--fw-semibold)
}

.outline-font--bold {

    font-weight: var(--fw-bold)
}

.outline-font--extrabold {

    font-weight: var(--fw-extrabold)
}

.outline-font--black {

    font-weight: var(--fw-black)
}
`;