
import { css } from 'lit';
export default css`
/* Apply standardized box sizing to the component. */
:host {
  box-sizing: border-box;
}
:host *,
:host *::before,
:host *::after {
  box-sizing: inherit;
}
[hidden] {
  display: none !important;
}
/* Apply component specific CSS */
:host {
  display: block;
}

outline-container {
  display: grid;
  height: 100%;
  grid-template-columns: repeat(12, minmax(0, 1fr));
}

:host([gap-size='large']) outline-container, :host([gap-size-sm='large']) outline-container, :host([gap-size-md='large']) outline-container, :host([gap-size-lg='large']) outline-container, :host([gap-size-xl='large']) outline-container, :host([gap-size-xxl='large']) outline-container, :host([gap-size-xxxl='large']) outline-container {
  row-gap: 4rem;
  -moz-column-gap: 0px;
       column-gap: 0px;
}

:host([gap-size='medium']) outline-container, :host([gap-size-sm='medium']) outline-container, :host([gap-size-md='medium']) outline-container, :host([gap-size-lg='medium']) outline-container, :host([gap-size-xl='medium']) outline-container, :host([gap-size-xxl='medium']) outline-container, :host([gap-size-xxxl='medium']) outline-container {
  row-gap: 2.5rem;
  -moz-column-gap: 0px;
       column-gap: 0px;
}

:host([gap-size='small']) outline-container, :host([gap-size-sm='small']) outline-container, :host([gap-size-md='small']) outline-container, :host([gap-size-lg='small']) outline-container, :host([gap-size-xl='small']) outline-container, :host([gap-size-xxl='small']) outline-container, :host([gap-size-xxxl='small']) outline-container {
  row-gap: 1rem;
  -moz-column-gap: 0px;
       column-gap: 0px;
}

:host([gap-size='none']) outline-container {
  gap: 0px;
}

@media (min-width: 640px) {
  :host([gap-size='large']) outline-container {
    row-gap: 4rem;
  }
  :host([gap-size='large']) outline-container {
    -moz-column-gap: 4rem;
         column-gap: 4rem;
  }

  :host([gap-size='medium']) outline-container {
    row-gap: 2.5rem;
  }

  :host([gap-size='medium']) outline-container {
    -moz-column-gap: 2.5rem;
         column-gap: 2.5rem;
  }

  :host([gap-size='small']) outline-container {
    row-gap: 1rem;
  }

  :host([gap-size='small']) outline-container {
    -moz-column-gap: 1rem;
         column-gap: 1rem;
  }
  :host([is-auto-fitted='true']) outline-container {
    grid-template-columns: repeat(auto-fit, minmax(0, 1fr));
  }
  :host([gap-size-sm='large']) outline-container {
    row-gap: 4rem;
  }
  :host([gap-size-sm='large']) outline-container {
    -moz-column-gap: 4rem;
         column-gap: 4rem;
  }
  :host([gap-size-sm='medium']) outline-container {
    row-gap: 2.5rem;
  }
  :host([gap-size-sm='medium']) outline-container {
    -moz-column-gap: 2.5rem;
         column-gap: 2.5rem;
  }
  :host([gap-size-sm='small']) outline-container {
    row-gap: 1rem;
  }
  :host([gap-size-sm='small']) outline-container {
    -moz-column-gap: 1rem;
         column-gap: 1rem;
  }
  :host([gap-size-sm='none']) outline-container {
    gap: 0px;
  }
}

@media (min-width: 768px) {
  :host([gap-size-md='large']) outline-container {
    row-gap: 4rem;
  }
  :host([gap-size-md='large']) outline-container {
    -moz-column-gap: 4rem;
         column-gap: 4rem;
  }
  :host([gap-size-md='medium']) outline-container {
    row-gap: 2.5rem;
  }
  :host([gap-size-md='medium']) outline-container {
    -moz-column-gap: 2.5rem;
         column-gap: 2.5rem;
  }
  :host([gap-size-md='small']) outline-container {
    row-gap: 1rem;
  }
  :host([gap-size-md='small']) outline-container {
    -moz-column-gap: 1rem;
         column-gap: 1rem;
  }
  :host([gap-size-md='none']) outline-container {
    gap: 0px;
  }
}

@media (min-width: 1024px) {
  :host([gap-size-lg='large']) outline-container {
    row-gap: 4rem;
  }
  :host([gap-size-lg='large']) outline-container {
    -moz-column-gap: 4rem;
         column-gap: 4rem;
  }
  :host([gap-size-lg='medium']) outline-container {
    row-gap: 2.5rem;
  }
  :host([gap-size-lg='medium']) outline-container {
    -moz-column-gap: 2.5rem;
         column-gap: 2.5rem;
  }
  :host([gap-size-lg='small']) outline-container {
    row-gap: 1rem;
  }
  :host([gap-size-lg='small']) outline-container {
    -moz-column-gap: 1rem;
         column-gap: 1rem;
  }
  :host([gap-size-lg='none']) outline-container {
    gap: 0px;
  }
}

@media (min-width: 1280px) {
  :host([gap-size-xl='large']) outline-container {
    row-gap: 4rem;
  }
  :host([gap-size-xl='large']) outline-container {
    -moz-column-gap: 4rem;
         column-gap: 4rem;
  }
  :host([gap-size-xl='medium']) outline-container {
    row-gap: 2.5rem;
  }
  :host([gap-size-xl='medium']) outline-container {
    -moz-column-gap: 2.5rem;
         column-gap: 2.5rem;
  }
  :host([gap-size-xl='small']) outline-container {
    row-gap: 1rem;
  }
  :host([gap-size-xl='small']) outline-container {
    -moz-column-gap: 1rem;
         column-gap: 1rem;
  }
  :host([gap-size-xl='none']) outline-container {
    gap: 0px;
  }
}

@media (min-width: 1440px) {
  :host([gap-size-xxl='large']) outline-container {
    row-gap: 4rem;
  }
  :host([gap-size-xxl='large']) outline-container {
    -moz-column-gap: 4rem;
         column-gap: 4rem;
  }
  :host([gap-size-xxl='medium']) outline-container {
    row-gap: 2.5rem;
  }
  :host([gap-size-xxl='medium']) outline-container {
    -moz-column-gap: 2.5rem;
         column-gap: 2.5rem;
  }
  :host([gap-size-xxl='small']) outline-container {
    row-gap: 1rem;
  }
  :host([gap-size-xxl='small']) outline-container {
    -moz-column-gap: 1rem;
         column-gap: 1rem;
  }
  :host([gap-size-xxl='none']) outline-container {
    gap: 0px;
  }
}

@media (min-width: 2180px) {
  :host([gap-size-xxl='large']) outline-container {
    row-gap: 4rem;
  }
  :host([gap-size-xxl='large']) outline-container {
    -moz-column-gap: 4rem;
         column-gap: 4rem;
  }
  :host([gap-size-xxl='medium']) outline-container {
    row-gap: 2.5rem;
  }
  :host([gap-size-xxl='medium']) outline-container {
    -moz-column-gap: 2.5rem;
         column-gap: 2.5rem;
  }
  :host([gap-size-xxl='small']) outline-container {
    row-gap: 1rem;
  }
  :host([gap-size-xxl='small']) outline-container {
    -moz-column-gap: 1rem;
         column-gap: 1rem;
  }
  :host([gap-size-xxl='none']) outline-container {
    gap: 0px;
  }
}
`;