import { html, TemplateResult, CSSResultGroup } from 'lit';
import { customElement, property, state } from 'lit/decorators.js';
import componentStyles from './ochsner-hero.css.lit';
import { OutlineElement } from '../../base/outline-element/outline-element';
import { SlotController } from '../../controllers/slot-controller';

import { AllowedHeroVariants } from './config';
/**
 * The Ochsner Hero Component
 * @element ochsner-hero
 */
@customElement('ochsner-hero')
export class OchsnerHero extends OutlineElement {
  slots = new SlotController(
    this, // This, the host element.
    true // To shift or not to shift LightDom nodes to ShadowDOM.
  );

  static styles: CSSResultGroup = componentStyles;

  /**
   * The variant to apply. Optional override to default provides a blue overlay and permits a button
   */
  @property({ type: String, attribute: 'hero-variant' })
  heroVariant: AllowedHeroVariants = 'default';

  /**
   * Ebnable the 'back' button.
   */
  @property({ type: Boolean, attribute: 'back-button' })
  backButton: boolean;

  /**
   * The title text to use.
   */
  @property({ type: String, attribute: 'hero-title' })
  heroTitle: string;

  /**
   * The image to use.
   */
  @property({ type: String, attribute: 'image' })
  image: String;

  @state() hasImageSlot: boolean;

  protected firstUpdated(): void {
    this.hasImageSlot = this.querySelector('[slot="image"]') !== null;
  }

  render(): TemplateResult {
    const hasImage = !this.hasImageSlot ? 'no-image' : null;

    return html`
      <div class="hero ${this.heroVariant} ${hasImage}">
        <div class="hero-inner">
          ${
            this.hasImageSlot &&
            this.backButton &&
            this.heroVariant === 'blueOverlay'
              ? html`<div class="btn-wrapper">
                  <button
                    class="btn"
                    @click=${() => history.back()}
                  >
                  <span class="icon-container">
                    <outline-icon name="icon-chev-circle-down" size="24px" mobile-size="24px" color="white" alt="Go Back"></outline-icon>
                  </span>
                    Back
                  </button>
                </div>`
              : null
          }
        
          ${this.imageTemplate()}
          
          ${this.headingTemplate(this.heroVariant)}

        </div>
      </div>
    `;
  }

  /**
   * Template partial for the Heading
   * returns TemplateResult | null
   */

  headingTemplate(heroVariant: AllowedHeroVariants): TemplateResult | null {
    if (!this.heroTitle) return null;
    const headingVariant = heroVariant === 'blueOverlay' ? 'light' : 'default';
    const hasImage = !this.hasImageSlot ? 'no-image' : null;

    return html`
      <div class="heading-wrapper ${heroVariant} ${hasImage}">
        <ochsner-heading
          level="h1"
          level-size="4xl"
          level-style="semibold"
          variant="${headingVariant}"
        >
          ${this.heroTitle}
        </ochsner-heading>
      </div>
      `;
  }

  /**
   * Template partial for the Image slot
   * returns TemplateResult | null
   */

  imageTemplate(): TemplateResult | null {
    if (!this.hasImageSlot) return null;

    return html`
      <div class="img-wrapper ${this.heroVariant}">
        <outline-image>
          <slot name="image"></slot>
        </outline-image>
      </div>
      `;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'ochsner-hero': OchsnerHero;
  }
}
