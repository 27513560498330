import { html, TemplateResult, CSSResultGroup } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import { OutlineButton } from '../../base/outline-button/outline-button';
import '../../base/outline-icon/outline-icon';
import componentStyles from './ochsner-campus-map-button.css.lit';
import { IconList } from '../../base/outline-icon/iconList';

/**
 * Ochsner campus map button custom compoonent.
 */
@customElement('ochsner-campus-map-button')
export class OchsnerCampusMapButton extends OutlineButton {
  static styles: CSSResultGroup = [componentStyles];

  /**
   * Choose which predefined icon to add to the button
   */
  @property() icon: keyof IconList;

  render(): TemplateResult {
    return html`
        <a
          class="btn campus-map"
          href=${this.url}
          target=${this.target}
          aria-disabled=${this.isDisabled}
          download
        >
          ${
            this.icon &&
            html`<outline-icon
            size="40px"
            mobile-size="40px"
            name="${this.icon}"
          ></outline-icon>`
          }
          <slot class="btn-label"></slot>
        </a>
        `;
  }
}
