import { CSSResultGroup, TemplateResult, html } from 'lit';
import { OchsnerCard } from '../ochsner-card/ochsner-card';
import { customElement, property, state } from 'lit/decorators.js';
import componentStyles from './ochsner-vertical-card.css.lit';
import { LinkedBlockController } from '../../controllers/linked-block-controller';

/**
 * The OchsnerVerticalCard component
 * @element ochsner-vertical-card
 * @slot headline - The text for the card
 */
@customElement('ochsner-vertical-card')
export class OchsnerVerticalCard extends OchsnerCard {
  static styles: CSSResultGroup = [componentStyles];

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  // We are using the controller to manage link behavior, but don't need to use it otherwise.
  private _linkedBlockController = new LinkedBlockController(
    this,
    () => this.shadowRoot?.querySelector(`#link`) ?? undefined
  );

  /**
   * The URL to use.
   */
  @property({ type: String, reflect: true, attribute: 'href' })
  href = '#';

  /**
   * The icon to use.
   */
  @property({ type: String, reflect: true, attribute: 'icon' })
  icon = 'icon-new-clipboard';

  @state() hasLink: boolean;

  render(): TemplateResult {
    return html`
      <div class="card card--vertical">
        <outline-icon
          name="${this.icon}"
          size="60px"
          mobile-size="60px"
        ></outline-icon>
        <a id="link" href="${this.href}">
          <slot class="headline" name="headline"></slot>
        </a>
      </div>
    `;
  }
}
