
import { css } from 'lit';
export default css`
/* Apply standardized box sizing to the component. */
:host {
  box-sizing: border-box;
}
:host *,
:host *::before,
:host *::after {
  box-sizing: inherit;
}
[hidden] {
  display: none !important;
}
/* Apply component specific CSS */
:host {
  display: block;
}

h1, h2, h3, h4, h5, h6 {
  margin: 0px;
  margin-bottom: 1rem;
  font-family: Montserrat, Helvetica, Arial, sans-serif;
}

/* Moved to top to allow easier override */

h6, .outline-text, .outline-text--base {
  font-size: var(--fs-base);
  line-height: 1.25rem;
  color: var(--neutral-black);
}

.light {
  color: var(--neutral-white) !important;
}

h5, .outline-text--lg {
  font-size: var(--fs-lg);
  line-height: 1.25rem;
  color: var(--brand-blueDark);
}

h4, .outline-text--xl {
  font-size: var(--fs-xl);
  line-height: 1.5rem;
  color: var(--brand-blue);
}

h3, .outline-text--2xl {
  font-size: var(--fs-2xl);
  line-height: 2rem;
  color: var(--brand-blue);
}

h2, .outline-text--3xl {
  font-size: var(--fs-3xl);
  line-height: 2.5rem;
  color: var(--brand-blueDark);
}

h1, .outline-text--4xl {
  font-size: var(--fs-4xl);
  line-height: 2.5rem;
  color: var(--brand-blue);
}

.outline-font--semibold {
  font-weight: var(--fw-semibold);
}

.outline-font--bold {
  font-weight: var(--fw-bold);
}

.header-border--top {
  border-top-style: solid;
  border-top-width: 1px;
  border-top-color: var(--neutral-grayMedium);
  padding-top: 1.5rem;
}
`;