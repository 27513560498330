import { CSSResultGroup, TemplateResult, html } from 'lit';
import { OchsnerCard } from '../ochsner-card/ochsner-card';
import { customElement, property, state } from 'lit/decorators.js';
import componentStyles from './ochsner-horizontal-link-card.css.lit';
import { LinkedBlockController } from '../../controllers/linked-block-controller';
import '../../base/outline-icon/outline-icon';
import '../../base/outline-image/outline-image';
import type { IconList } from '../../base/outline-icon/iconList';

export enum LinkTypes {
  Internal = 'internal',
  External = 'external',
}
export type LinkType = LinkTypes;
/**
 * The OchsnerHorizontalLinkCard component
 * @element ochsner-horizontal-link-card
 * @slot title - The text for the card
 * @slot image - The optional image for the card
 */
@customElement('ochsner-horizontal-link-card')
export class OchsnerHorizontalLinkCard extends OchsnerCard {
  static styles: CSSResultGroup = [componentStyles];

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  // We are using the controller to manage link behavior, but don't need to use it otherwise.
  private _linkedBlockController = new LinkedBlockController(
    this,
    () => this.shadowRoot?.querySelector(`#link`) ?? undefined
  );

  /**
   * The URL to use.
   */
  @property({ type: String, reflect: true, attribute: 'href' })
  href = '#';

  /**
   * The icon to use.
   */
  @property({ type: String, reflect: true, attribute: 'icon' })
  icon: IconList;

  /**
   * Link is external or not
   */
  @property({ type: String, reflect: true, attribute: 'link-type' })
  linkType: LinkType;

  /**
   * The image to use.
   */
  @property({ type: String, reflect: true, attribute: 'image' })
  image: String;

  @state() hasLink: boolean;

  getIconOrImage(): TemplateResult | null {
    if (this.icon && !this.image) {
      return html`
        <outline-icon 
          class="left-icon"
          name="${this.icon}"
          size="40px"
          mobile-size="40px"></outline-icon>`;
    }
    if (this.image) {
      return html`<outline-image class="card--horizontal__image"><slot class="card--horizontal__image" name="image"></slot></outline-image>`;
    }
    return null;
  }
  render(): TemplateResult {
    return html`
      <div class="card card--horizontal">
        ${this.getIconOrImage()}
        <a id="link" href="${this.href}">
          <slot class="headline" name="headline"></slot>
        </a>
        ${
          this.linkType === 'external'
            ? html`<outline-icon 
              class="right-icon"
              size="16px"
              mobile-size="16px"
              name="icon-external-link"
            ></outline-icon>`
            : html`<outline-icon 
              class="right-icon"
              size="16px"
              mobile-size="16px"
              name="icon-chevron"
            ></outline-icon>`
        }
      </div>
    `;
  }
}
