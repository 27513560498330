
import { css } from 'lit';
export default css`
/* Apply standardized box sizing to the component. */
:host {
  box-sizing: border-box;
}
:host *,
:host *::before,
:host *::after {
  box-sizing: inherit;
}
[hidden] {
  display: none !important;
}
/* Apply component specific CSS */
:host {
  display: block;
  border-radius: 0.25rem;
}

code[class*='language-'],
pre[class*='language-'] {
  color: #ccc;
  background: none;
  font-family: Consolas, Monaco, 'Andale Mono', 'Ubuntu Mono', monospace;
  font-size: 1em;
  text-align: left;
  white-space: pre;
  word-spacing: normal;
  word-break: normal;
  word-wrap: normal;
  line-height: 1.5;

  -moz-tab-size: 4;
  tab-size: 4;

  -webkit-hyphens: none;
  hyphens: none;
}

/* Code blocks */

pre[class*='language-'] {
  padding: 0 1rem;
  margin: 0 0;
  overflow: auto;
}

:not(pre) > code[class*='language-'],
pre[class*='language-'] {
  background: #2d2d2d;
  border-radius: 5px;
}

/* Inline code */

:not(pre) > code[class*='language-'] {
  padding: 0.1em;
  border-radius: 0.3em;
  white-space: normal;
}

.token.comment,
.token.block-comment,
.token.prolog,
.token.doctype,
.token.cdata {
  color: #999;
}

.token.punctuation {
  color: #ccc;
}

.token.tag,
.token.attr-name,
.token.namespace,
.token.deleted {
  color: #e2777a;
}

.token.function-name {
  color: #6196cc;
}

.token.boolean,
.token.number,
.token.function {
  color: #f08d49;
}

.token.property,
.token.class-name,
.token.constant,
.token.symbol {
  color: #f8c555;
}

.token.selector,
.token.important,
.token.atrule,
.token.keyword,
.token.builtin {
  color: #cc99cd;
}

.token.string,
.token.char,
.token.attr-value,
.token.regex,
.token.variable {
  color: #7ec699;
}

.token.operator,
.token.entity,
.token.url {
  color: #67cdcc;
}

.token.important,
.token.bold {
  font-weight: bold;
}

.token.italic {
  font-style: italic;
}

.token.entity {
  cursor: help;
}

.token.inserted {
  color: green;
}

pre[data-line] {
  position: relative;
  padding: 1em 0 1em 3em;
}

.line-highlight {
  position: absolute;
  left: 0;
  right: 0;
  padding: inherit 0;
  margin-top: 1em; /* Same as .prism’s padding-top */
  background: linear-gradient(
    to right,
    hsla(24, 20%, 50%, 0.1) 70%,
    hsla(24, 20%, 50%, 0)
  );

  pointer-events: none;

  line-height: inherit;
  white-space: pre;
}

@media print {
  .line-highlight {
    /*
		 * This will prevent browsers from replacing the background color with white.
		 * It's necessary because the element is layered on top of the displayed code.
		 */
    -webkit-print-color-adjust: exact;
    color-adjust: exact;
  }
}

.line-highlight:before,
.line-highlight[data-end]:after {
  content: attr(data-start);
  position: absolute;
  top: 0.4em;
  left: 0.6em;
  min-width: 1em;
  padding: 0 0.5em;
  background-color: hsla(24, 20%, 50%, 0.4);
  color: hsl(24, 20%, 95%);
  font: bold 65%/1.5 sans-serif;
  text-align: center;
  vertical-align: 0.3em;
  border-radius: 999px;
  text-shadow: none;
  box-shadow: 0 1px white;
}

.line-highlight[data-end]:after {
  content: attr(data-end);
  top: auto;
  bottom: 0.4em;
}

.line-numbers .line-highlight:before,
.line-numbers .line-highlight:after {
  content: none;
}

pre[id].linkable-line-numbers span.line-numbers-rows {
  pointer-events: all;
}

pre[id].linkable-line-numbers span.line-numbers-rows > span:before {
  cursor: pointer;
}

pre[id].linkable-line-numbers span.line-numbers-rows > span:hover:before {
  background-color: rgba(128, 128, 128, 0.2);
}

pre[class*='language-'].line-numbers {
  position: relative;
  padding-left: 3.8em;
  counter-reset: linenumber;
}

pre[class*='language-'].line-numbers > code {
  position: relative;
  white-space: inherit;
}

.line-numbers .line-numbers-rows {
  position: absolute;
  pointer-events: none;
  top: -0.13em; /* wtf */
  left: -3.8em;
  width: 3em; /* works for line-numbers below 1000 lines */
  border-right: 1px solid #999;

  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
}

.line-numbers-rows > span {
  pointer-events: none;
  display: block;
  counter-increment: linenumber;
}

.line-numbers-rows > span::before {
  content: counter(linenumber);
  color: #999;
  display: block;
  padding-right: 0.8em;
  text-align: right;
}

#copy-button {
  /* width: 90px; */
  /* background-color: white; */
  padding: 4px;
  /* justify-self: end; */
  position: absolute;
  top: 5px;
  right: 5px;
}

.wrapper {
  display: flex;
  flex-direction: column;
}
`;