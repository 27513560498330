import { CSSResultGroup, TemplateResult, html } from 'lit';
import { OchsnerCard } from '../ochsner-card/ochsner-card';
import { customElement, property, state } from 'lit/decorators.js';
import { classMap } from 'lit/directives/class-map.js';
import componentStyles from './ochsner-location-card.css.lit';
import '../../base/outline-image/outline-image';
import { AllowedCardVariants } from './config';

/**
 * The OchsnerLocationCard component
 * @element ochsner-location-card
 * @slot image - The image for the card
 */
@customElement('ochsner-location-card')
export class OchsnerLocationCard extends OchsnerCard {
  static styles: CSSResultGroup = [componentStyles];

  /**
   * The Title text to use.
   */
  @property({ type: String, reflect: true, attribute: 'title' })
  title = 'Title Text';

  /**
   * The Location text to use.
   */
  @property({ type: String, reflect: true, attribute: 'location' })
  location = 'Location Text';

  /**
   * The left link text to use.
   */
  @property({ type: String, reflect: true, attribute: 'left-link-text' })
  leftLinkText: String;

  /**
   * The left link  to use.
   */
  @property({ type: String, reflect: true, attribute: 'left-link-url' })
  leftLinkUrl: String;

  /**
   * The right link text to use.
   */
  @property({ type: String, reflect: true, attribute: 'right-link-text' })
  rightLinkText: String;

  /**
   * The right link  to use.
   */
  @property({ type: String, reflect: true, attribute: 'right-link-url' })
  rightLinkUrl: String;

  /**
   * The variant type default or map.
   */
  @property({ type: String, reflect: true, attribute: 'card-variant' })
  cardVariant: AllowedCardVariants = 'default';

  @state() hasImageSlot: boolean;

  protected firstUpdated(): void {
    this.hasImageSlot = this.querySelector('[slot="image"]') !== null;
  }

  render(): TemplateResult {
    const isMap = this.cardVariant === 'map';
    const cardClasses = {
      'card': true,
      'card--location': true,
      'map': isMap,
    };
    const multiButton =
      this.leftLinkUrl &&
      this.leftLinkText &&
      this.rightLinkUrl &&
      this.rightLinkText
        ? 'multi'
        : null;
    return html`
      <article class="${classMap(cardClasses)}">
          <div class="top-wrapper">
            ${this.imageTemplate()}
            <div class="text-wrapper">
              <h3>${this.title}</h3>
              <div>${this.location}</div>
              <slot name="direction-link"></slot>
            </div>
          </div>
          ${isMap ? html`<hr>` : null}
          <div class="button-wrapper ${multiButton}">
            ${
              this.leftLinkUrl && this.leftLinkText
                ? html`<a href="${this.leftLinkUrl}">${this.leftLinkText}</a>`
                : null
            }
            ${
              this.rightLinkUrl && this.rightLinkText
                ? html`<a href="${this.rightLinkUrl}">${this.rightLinkText}</a>`
                : null
            }
          </div>
        </article>
    `;
  }
  /**
   * Template partial for the Image slot
   * returns TemplateResult | null
   */
  imageTemplate(): TemplateResult | null {
    if (!this.hasImageSlot) return null;

    return html`
      <outline-image class="card--location__image">
        <slot name="image" class="card--location_image"></slot>
      </outline-image>`;
  }
}
