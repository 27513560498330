// Our base component, which all others extend.
import { OutlineLink } from '../../base/outline-link/outline-link';
import { html, TemplateResult, CSSResultGroup } from 'lit';
import { customElement, property, query } from 'lit/decorators.js';
import { ifDefined } from 'lit/directives/if-defined.js';
import componentStyles from './ochsner-link.css.lit';
import { IconList } from '../../base/outline-icon/iconList';

/**
 * The Outline  Link component
 * @slot - The default, and only slot for this element.
 */
@customElement('ochsner-link')
export class OchsnerLink extends OutlineLink {
  static styles: CSSResultGroup = [componentStyles];

  @query('slot')
  codeSlot: HTMLSlotElement;

  /**
   * Link url
   */
  lastWord: String | boolean = false;

  /**
   * Choose which predefined icon to add to the link
   */
  @property() icon: keyof IconList;

  protected firstUpdated(): void {
    if (this.icon) {
      const text =
        typeof this.linkText == 'string'
          ? this.linkText
          : (this.codeSlot?.assignedNodes()[0].nodeValue as string);
      this.lastWord = text.trim().split(' ').splice(-1)[0];
      this.linkText = text.substr(0, text.lastIndexOf(' '));
    }
  }

  /**
   * This methodology allows us to create a component that can use properties
   * passed into it to generate a link element (<a>). This requires the linkHref
   * attribute is passed, otherwise, anything passed in will override the default
   * content in the slot allowing you to pass a pre-generated link into the
   * outline-link component wrapper.
   */
  render(): TemplateResult {
    return html`${
      this.linkHref
        ? html` <a
          href=${this.linkHref}
          rel="${ifDefined(this.linkRel)}"
          target="${ifDefined(this.linkTarget)}"
        >
          ${
            this.linkText
              ? html`${this.linkText}
            ${
              this.icon &&
              html`
              <span>${this.lastWord}
                <outline-icon
                  class="link-icon"
                  size="20px"
                  mobile-size="20px"
                  name="${this.icon}"
                ></outline-icon>
              </span>
            `
            }
          `
              : html`<slot></slot>`
          }
          
        </a>`
        : html`<slot></slot>`
    }`;
  }
}
