
import { css } from 'lit';
export default css`
/* Apply standardized box sizing to the component. */
:host {
  box-sizing: border-box;
}
:host *,
:host *::before,
:host *::after {
  box-sizing: inherit;
}
[hidden] {
  display: none !important;
}
/* Apply component specific CSS */
:host {

  display: block;

  overflow: hidden;
}

  :host section {

  display: flex
}

  :host section {

  flex-direction: column
}

  :host section {

  overflow: hidden
}

  :host section {

  text-align: left
}

  :host p {

  margin-bottom: 2.5rem
}

.outline-shadow {

  --tw-shadow: 0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1);

  --tw-shadow-colored: 0 1px 3px 0 var(--tw-shadow-color), 0 1px 2px -1px var(--tw-shadow-color);

  box-shadow: 0 0 #0000, 0 0 #0000, var(--tw-shadow);

  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
}

.outline-rounded-2xl {

  border-radius: 1rem
}

.outline-bg-primary {

  background-color: var(--demo-darkBlue)
}

.outline-bg-secondary {

  background-color: var(--demo-mediumBlue)
}

.outline-bg-tertiary {

  background-color: var(--demo-lightBlue)
}

.outline-bg-black {

  background-color: var(--neutral-black)
}

.outline-bg-white {

  background-color: var(--neutral-white)
}

.outline-bg-gray {

  background-color: var(--neutral-grayMedium)
}

.outline-bg-lightgray {

  background-color: var(--neutral-grayLight)
}
`;