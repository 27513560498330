
import { css } from 'lit';
export default css`
/* Apply standardized box sizing to the component. */
:host {
  box-sizing: border-box;
}
:host *,
:host *::before,
:host *::after {
  box-sizing: inherit;
}
[hidden] {
  display: none !important;
}
/* Apply component specific CSS */
:host {
  /* flex and border-radius constrain the actual boundaries of the element,
   preventing literal edge cases where a user can click on the edge or corner of the button and defeate states like disabled */
  display: flex;
  border-radius: 0.75rem;
}

.btn[aria-disabled='true'] {
  pointer-events: none !important;
  cursor: not-allowed !important;
  background-color: var(--demo-lightGray) !important;
  color: var(--demo-darkGray) !important;
}

.btn {
  display: inline-flex;
  cursor: pointer;
  align-content: center;
  border-radius: 0.75rem;
  font-family: Hind, Helvetica, Arial, sans-serif;
  line-height: 1.25;
  text-decoration: none;
  transition-property: background-color, border-color, color, fill, stroke;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 300ms;
}

.btn outline-icon {
  position: relative;
  margin-left: 0.5rem;
  margin-bottom: -0.5rem;
  width: 1.5rem;
}

.btn.primary {
  background-color: var(--demo-darkBlue);
  color: var(--neutral-white);
}

.btn.primary:hover, .btn.primary:focus {
  background-color: var(--neutral-black);
}

.btn.primary:hover, .btn.primary:focus {
  color: var(--neutral-white);
}

.btn.secondary {
  background-color: var(--demo-lightGray);
  color: var(--demo-darkGray);
}

.btn.secondary:hover, .btn.secondary:focus {
  background-color: var(--neutral-black);
}

.btn.secondary:hover, .btn.secondary:focus {
  color: var(--neutral-white);
}

.btn.tertiary {
  background-color: var(--demo-lightBlue);
  color: var(--demo-darkGray);
}

.btn.tertiary:hover, .btn.tertiary:focus {
  background-color: var(--neutral-black);
}

.btn.tertiary:hover, .btn.tertiary:focus {
  color: var(--neutral-white);
}

.btn.none {
  display: flex;
  align-items: center;
  padding-left: 1rem;
  padding-right: 1rem;
  font-size: var(--fs-base);
  line-height: var(--lh-base);
}

.small {
  padding-left: 0.25rem;
  padding-right: 0.25rem;
  font-size: var(--fs-sm);
  line-height: var(--lh-sm);
}

.medium {
  padding-left: 1rem;
  padding-right: 1rem;
  font-size: var(--fs-lg);
  line-height: var(--lh-lg);
}

.large {
  padding-left: 3rem;
  padding-right: 3rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
  font-size: var(--fs-lg);
  line-height: var(--lh-lg);
}
`;