
import { css } from 'lit';
export default css`
/* Apply standardized box sizing to the component. */
:host {
  box-sizing: border-box;
}
:host *,
:host *::before,
:host *::after {
  box-sizing: inherit;
}
[hidden] {
  display: none !important;
}
/* Apply component specific CSS */
:host {
}

.card--location {
  margin-bottom: 1.5rem;
  cursor: pointer;
  border-radius: 0.5rem;
  background-color: var(--neutral-white);
  padding-bottom: 0px;
  font-size: var(--fs-lg);
  line-height: var(--lh-lg);
  --tw-shadow: 0px 2px 5px 0px #00000026;
  --tw-shadow-colored: 0px 2px 5px 0px var(--tw-shadow-color);
  box-shadow: 0 0 #0000, 0 0 #0000, var(--tw-shadow);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.card--location:focus-within {
  background-color: var(--brand-blueLight-10);
  --tw-shadow: 0 0 0 1px rgb(33, 74, 222);
  --tw-shadow-colored: 0 0 0 1px var(--tw-shadow-color);
  box-shadow: 0 0 #0000, 0 0 #0000, var(--tw-shadow);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.card--location .top-wrapper {
  display: flex;
}

.card--location .top-wrapper {
  align-items: stretch;
}

.card--location .top-wrapper {
  padding-left: 1rem;
  padding-right: 1rem;
}

.card--location .top-wrapper {
  padding-top: 1.25rem;
}

.card--location .top-wrapper {
  padding-bottom: 1.75rem;
}

.card--location .top-wrapper .text-wrapper {
  display: flex;
}

.card--location .top-wrapper .text-wrapper {
  flex-direction: column;
}

.card--location .top-wrapper .text-wrapper {
  justify-content: space-between;
}

.card--location .top-wrapper .text-wrapper {
  font-family: Hind, Helvetica, Arial, sans-serif;
}

.card--location .top-wrapper .text-wrapper {
  font-size: var(--fs-base);
  line-height: var(--lh-base);
}

.card--location .top-wrapper .text-wrapper {
  color: var(--neutral-grayDark);
}

.card--location .top-wrapper h3 {
  margin: 0px;
}

.card--location .top-wrapper h3 {
  margin-bottom: 0.25rem;
}

.card--location .top-wrapper h3 {
  padding: 0px;
}

.card--location .top-wrapper h3 {
  font-family: Montserrat, Helvetica, Arial, sans-serif;
}

.card--location .top-wrapper h3 {
  font-size: var(--fs-lg);
  line-height: var(--lh-lg);
}

.card--location .top-wrapper h3 {
  font-weight: var(--fw-semibold);
}

.card--location .top-wrapper h3 {
  line-height: 1.5rem;
}

.card--location .top-wrapper h3 {
  color: var(--brand-blue);
}

.card--location .top-wrapper outline-image {
      min-width: -webkit-fit-content;
      min-width: -moz-fit-content;
      min-width: fit-content;
    }

.card--location .button-wrapper {
  display: grid;
}

.card--location .button-wrapper {
  grid-template-columns: repeat(1, minmax(0, 1fr));
}

.card--location .button-wrapper {
  align-items: stretch;
}

.card--location .button-wrapper {
  justify-content: center;
}

.card--location .button-wrapper {
  overflow: hidden;
}

.card--location .button-wrapper {
  border-bottom-right-radius: 0.5rem;
  border-bottom-left-radius: 0.5rem;
}

.card--location .button-wrapper {
  background-color: var(--brand-blue-50);
}

.card--location .button-wrapper a {
  display: flex;
}

.card--location .button-wrapper a {
  align-items: center;
}

.card--location .button-wrapper a {
  justify-content: center;
}

.card--location .button-wrapper a {
  background-image: linear-gradient(to bottom, var(--tw-gradient-stops));
}

.card--location .button-wrapper a {
  --tw-gradient-from: var(--brand-blue);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgb(255 255 255 / 0));
}

.card--location .button-wrapper a {
  --tw-gradient-to: var(--brand-blueDark);
}

.card--location .button-wrapper a {
  padding-left: 1.25rem;
  padding-right: 1.25rem;
}

.card--location .button-wrapper a {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.card--location .button-wrapper a {
  text-align: center;
}

.card--location .button-wrapper a {
  font-family: Montserrat, Helvetica, Arial, sans-serif;
}

.card--location .button-wrapper a {
  font-size: var(--fs-base);
  line-height: var(--lh-base);
}

.card--location .button-wrapper a {
  font-weight: var(--fw-semibold);
}

.card--location .button-wrapper a {
  line-height: 1.5;
}

.card--location .button-wrapper a {
  color: var(--neutral-white);
}

.card--location .button-wrapper a {
  text-decoration: none;
}

.card--location .button-wrapper a:first-of-type {
  border-bottom-left-radius: 0.5rem;
}

.card--location .button-wrapper a:last-of-type {
  border-bottom-right-radius: 0.5rem;
}

.card--location .button-wrapper a:hover {
  background-color: var(--brand-blueDark);
}

.card--location .button-wrapper a:hover {
  text-decoration: underline;
}

.card--location .button-wrapper a:focus {
  background-color: var(--brand-blueDark);
}

.card--location .button-wrapper a:focus {
  text-decoration: underline;
}

.card--location .button-wrapper.multi {
  grid-template-columns: repeat(2, minmax(0, 1fr));
}

.card--location .button-wrapper.multi {
  gap: 1px;
}

.card--location .button-wrapper.multi {
    white-space: nowrap;
  }

.card--location img, .card--location ::slotted(img) {
  margin-right: 0.75rem !important;
}

.card--location img, .card--location ::slotted(img) {
  height: var(--spacing-18) !important;
}

.card--location img, .card--location ::slotted(img) {
  width: var(--spacing-18) !important;
}

.card--location img, .card--location ::slotted(img) {
  border-radius: 0.5rem !important;
}

.card--location.map {
  position: relative;
  margin: 1rem;
  background-image: none;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  box-shadow: 0 0 #0000, 0 0 #0000, var(--tw-shadow);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.card--location.map:focus-within {
  background-color: var(--neutral-white);
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  box-shadow: 0 0 #0000, 0 0 #0000, var(--tw-shadow);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.card--location.map .top-wrapper {
  padding: 0px;
}

.card--location.map hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.card--location.map hr {
  margin-left: 0px;
  margin-right: 0px;
}

.card--location.map hr {
  border-bottom-width: 0px;
}

.card--location.map hr {
  border-left-width: 0px;
}

.card--location.map hr {
  border-right-width: 0px;
}

.card--location.map hr {
  border-top-color: var(--neutral-grayMedium);
}

.card--location.map .button-wrapper {
  margin: 0px;
}

.card--location.map .button-wrapper {
  border-radius: 0.5rem;
}

.card--location.map ::slotted([slot='direction-link']) {
  font-family: Hind, Helvetica, Arial, sans-serif;
}

.card--location.map ::slotted([slot='direction-link']) {
  font-weight: var(--fw-semibold);
}

.card--location.map ::slotted([slot='direction-link']) {
  color: var(--ui-accent);
}

.card--location.map ::slotted([slot='direction-link']) {
  text-decoration: underline;
}
`;