
import { css } from 'lit';
export default css`
/* Apply standardized box sizing to the component. */
:host {
  box-sizing: border-box;
}
:host *,
:host *::before,
:host *::after {
  box-sizing: inherit;
}
[hidden] {
  display: none !important;
}
/* Apply component specific CSS */
:host footer {
  display: flex;
}
  :host footer {
  flex-direction: column;
}
  :host footer {
  align-items: center;
}
  :host footer {
  background-color: var(--brand-blueDark);
}
  :host footer {
  padding-left: 1rem;
  padding-right: 1rem;
}
  :host footer {
  padding-top: 2rem;
  padding-bottom: 2rem;
}
  :host .footer-social-links {
  margin-top: 1.75rem;
}
  :host .footer-social-links {
  margin-bottom: 1rem;
}
  :host .footer-social-links {
  display: flex;
}
  :host .footer-social-links {
  list-style-type: none;
}
  :host .footer-social-links {
  padding: 0px;
}
  :host .footer-social-link-item {
  margin-right: 1.5rem;
}
  :host .footer-social-link-item:last-of-type {
  margin-right: 0px;
}
  :host .footer-divider {
  width: 100%;
}
  :host .footer-divider {
  border-left-width: 0px;
  border-right-width: 0px;
}
  :host .footer-divider {
  border-bottom-width: 0px;
}
  :host .footer-divider {
  border-top-color: var(--brand-blueLight);
}
  :host .footer-scroll-top-btn {
  margin-top: 1rem;
}
  :host .footer-scroll-top-btn {
  display: flex;
}
  :host .footer-scroll-top-btn {
  align-items: center;
}
  :host .footer-scroll-top-btn {
  border-width: 0px;
}
  :host .footer-scroll-top-btn {
  background-image: none;
}
  :host .footer-scroll-top-btn {
  font-family: Montserrat, Helvetica, Arial, sans-serif;
}
  :host .footer-scroll-top-btn {
  font-size: var(--fs-sm);
  line-height: var(--lh-sm);
}
  :host .footer-scroll-top-btn {
  font-weight: var(--fw-semibold);
}
  :host .footer-scroll-top-btn {
  color: var(--neutral-white);
}
  :host .footer-scroll-top-btn {
    background: none;
  }
  :host .footer-scroll-top-icon-container {
  margin-right: 0.75rem;
}
  :host .hidden-text {
    clip: rect(1px, 1px, 1px, 1px);
    -webkit-clip-path: inset(50%);
            clip-path: inset(50%);
    height: 1px;
    width: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
  }
`;