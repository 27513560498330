
import { css } from 'lit';
export default css`
/* Apply standardized box sizing to the component. */
:host {
  box-sizing: border-box;
}
:host *,
:host *::before,
:host *::after {
  box-sizing: inherit;
}
[hidden] {
  display: none !important;
}
/* Apply component specific CSS */
.accordion-button {
  position: relative;
  padding-left: 1rem;
  padding-right: 1rem;
  font-family: Montserrat, Helvetica, Arial, sans-serif;
  font-size: var(--fs-lg);
  line-height: var(--lh-lg);
  font-weight: var(--fw-bold);
}

.accordion-button.inactive {
  color: var(--brand-blue);
}

.accordion-button.active {
  color: var(--ui-accent);
}

.accordion-button.active::after {
    content: '';
    width: calc(100% - 2rem);
  }

.accordion-button.active::after {
  position: absolute;
}

.accordion-button.active::after {
  bottom: 0px;
}

.accordion-button.active::after {
  border-width: 0px;
}

.accordion-button.active::after {
  border-bottom-width: 1px;
}

.accordion-button.active::after {
  border-style: solid;
}

.accordion-button.active::after {
  border-bottom-color: var(--neutral-grayMedium);
}

.accordion-panel {
  margin-bottom: 1.5rem;
  border-radius: 0.5rem;
  --tw-shadow: 6px 6px 15px rgba(0, 0, 0, 0.15);
  --tw-shadow-colored: 6px 6px 15px var(--tw-shadow-color);
  box-shadow: 0 0 #0000, 0 0 #0000, var(--tw-shadow);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  border-width: 1px;
  border-style: solid;
  border-color: var(--neutral-transparent);
}

.accordion-panel.active {
  border-width: 1px;
  border-color: var(--ui-accent);
  background-color: var(--neutral-white);
}

.accordion-label {
  padding-top: 1rem;
  padding-bottom: 1rem;
  max-width: 84%;
}

.accordion-heading {
  min-height: 3.75rem;
}

.accordion-icon {
  width: 2.5rem;
  justify-content: flex-end;
}

.accordion-icon.active {
  color: var(--ui-accent);
}

.accordion-content {
  padding: 1rem;
}
`;