import { CSSResultGroup, TemplateResult, html } from 'lit';
import { OutlineAccordionPanel } from '../../base/outline-accordion-panel/outline-accordion-panel';
import { customElement } from 'lit/decorators.js';
import componentStyles from './ochsner-accordion-panel.css.lit';
import '../../base/outline-icon/outline-icon';

/**
 * The OchsnerAccordionPanel component
 * @element ochsner-accordion-panel
 * @slot heading: The title text for the panel component.
 * @slot default slot: The main panel content, visible when the panel is open.
 */
@customElement('ochsner-accordion-panel')
export class OchsnerAccordionPanel extends OutlineAccordionPanel {
  static styles: CSSResultGroup = [
    OutlineAccordionPanel.styles,
    componentStyles,
  ];

  render(): TemplateResult {
    const isActive = this.active ? 'active' : 'inactive';

    return html` <div class="accordion-panel ${isActive}">
      <div class="accordion-heading">
        <button
          class="accordion-button ${isActive}"
          id="${this.id}-button"
          aria-expanded=${this.active}
          aria-controls="${this.id}-info"
        >
          <span class="accordion-label">
            <slot name="heading"> </slot>
          </span>
          <span class="accordion-icon ${isActive}">
            <outline-icon
              size="28px"
              mobile-size="28px"
              ?decorative="${true}"
              name="${
                this.active ? 'icon-chev-circle-solid' : 'icon-chev-circle-down'
              }"
            ></outline-icon>
          </span>
        </button>
      </div>
      <div
        class="accordion-content"
        role="region"
        aria-labelledby="${this.id}-button"
        id="${this.id}-info"
        .hidden=${!this.active}
        aria-hidden="${!this.active}"
      >
        <slot></slot>
      </div>
    </div>`;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'ochsner-accordion-panel': OchsnerAccordionPanel;
  }
}
