
import { css } from 'lit';
export default css`
/* Apply standardized box sizing to the component. */
:host {
  box-sizing: border-box;
}
:host *,
:host *::before,
:host *::after {
  box-sizing: inherit;
}
[hidden] {
  display: none !important;
}
/* Apply component specific CSS */
a, ::slotted(a) {

    font-family: Hind, Helvetica, Arial, sans-serif;

    color: var(--demo-darkBlue);

    text-decoration: none;

    transition-property: background-color, border-color, color, fill, stroke;

    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);

    transition-duration: 300ms
}

a:hover, ::slotted(a:hover) {

    color: var(--demo-mediumBlue);

    text-decoration: underline
}

a.active, ::slotted(a.active) {

    text-decoration: underline
}
`;