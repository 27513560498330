import { html, TemplateResult, CSSResultGroup } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import { OutlineButton } from '../../base/outline-button/outline-button';
import componentStyles from './ochsner-button.css.lit';
import { IconList } from '../../base/outline-icon/iconList';

export type ButtonVariant = 'default' | 'secondary' | 'outline' | 'white';

export type ButtonSize = 'small' | 'large';
/**
 * The Ochsner Button component
 * @slot - The default, and only slot for this element.
 */

@customElement('ochsner-button')
export class OchsnerButton extends OutlineButton {
  static styles: CSSResultGroup = [componentStyles];

  /**
   * The button size to use.
   */
  @property({ type: String }) size: ButtonSize = 'large';

  /**
   * Choose which predefined icon to add to the link
   */
  @property() icon: keyof IconList;

  /**
   * If used as a button trigger live chat popup.
   */
  @property({ type: Boolean, reflect: true, attribute: 'trigger-live-chat' })
  triggerLiveChat = false;

  /**
   * If set will override url
   */
  @property({ type: String }) deepLink: string;

  constructor() {
    super();
  }

  render(): TemplateResult {
    return this.deepLink || this.url
      ? html` <a
          class="btn ${this.variant} ${this.size}"
          href=${this.deepLink || this.url} 
          target=${this.target}
          aria-disabled=${this.isDisabled}
        >
          ${
            this.icon &&
            html`<outline-icon
            size="26px"
            mobile-size="26px"
            name="${this.icon}"
          ></outline-icon>`
          }
          <slot></slot>
        </a>`
      : html`<button
          class="btn ${this.variant} ${this.size}"
          aria-disabled="${this.isDisabled}"
          .onkeyup="${this.onKeyUp}"
        >
          ${
            this.icon &&
            html`<outline-icon
            size="26px"
            mobile-size="26px"
            name="${this.icon}"
          ></outline-icon>`
          }
          <slot></slot>
        </button> `;
  }
}
