
import { css } from 'lit';
export default css`
/* Apply standardized box sizing to the component. */
:host {
  box-sizing: border-box;
}
:host *,
:host *::before,
:host *::after {
  box-sizing: inherit;
}
[hidden] {
  display: none !important;
}
/* Apply component specific CSS */
:host, a, ::slotted(a) {
  font-family: Hind, Helvetica, Arial, sans-serif;
  text-decoration: none;
  transition-property: background-color, border-color, color, fill, stroke;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 300ms;
  color: var(--outline-link-color-default)
}

:host(:hover), a:hover, a:hover span, ::slotted(a:hover) {
  text-decoration: underline;
  color: var(--outline-link-color-hover) !important
}

:host(:focus), a:focus, a:hover span, ::slotted(a:focus) {
  text-decoration: underline;
  outline: 2px solid transparent;
  outline-offset: 2px;
  color: var(--outline-link-color-focus) !important
}

span {
  display: inline-block
}

.link-icon {
  margin-left: 0.125rem;
  display: inline-flex;
  vertical-align: sub
}
`;