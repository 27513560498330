
import { css } from 'lit';
export default css`
/* Apply standardized box sizing to the component. */
:host {
  box-sizing: border-box;
}
:host *,
:host *::before,
:host *::after {
  box-sizing: inherit;
}
[hidden] {
  display: none !important;
}
/* Apply component specific CSS */
:host {
    grid-column: span 12 / span 12;
    display: flex;
    flex-direction: column;
}
  :host([row-span='1']) {
    grid-row: span 1 / span 1;
}
  :host([row-span='2']) {
    grid-row: span 2 / span 2;
}
  :host([row-span='3']) {
    grid-row: span 3 / span 3;
}
  :host([row-span='4']) {
    grid-row: span 4 / span 4;
}
  :host([row-span='5']) {
    grid-row: span 5 / span 5;
}
  :host([row-span='6']) {
    grid-row: span 6 / span 6;
}
  :host([col-span-default='1']) {
    grid-column: span 1 / span 1;
}
  :host([col-span-default='2']) {
    grid-column: span 2 / span 2;
}
  :host([col-span-default='3']) {
    grid-column: span 3 / span 3;
}
  :host([col-span-default='4']) {
    grid-column: span 4 / span 4;
}
  :host([col-span-default='5']) {
    grid-column: span 5 / span 5;
}
  :host([col-span-default='6']) {
    grid-column: span 6 / span 6;
}
  :host([col-span-default='7']) {
    grid-column: span 7 / span 7;
}
  :host([col-span-default='8']) {
    grid-column: span 8 / span 8;
}
  :host([col-span-default='9']) {
    grid-column: span 9 / span 9;
}
  :host([col-span-default='10']) {
    grid-column: span 10 / span 10;
}
  :host([col-span-default='11']) {
    grid-column: span 11 / span 11;
}
  :host([col-span-default='12']) {
    grid-column: span 12 / span 12;
}
  @media (min-width: 640px) {
    :host([col-span-sm='1']) {
        grid-column: span 1 / span 1;
    }

    :host([col-span-sm='2']) {
        grid-column: span 2 / span 2;
    }

    :host([col-span-sm='3']) {
        grid-column: span 3 / span 3;
    }

    :host([col-span-sm='4']) {
        grid-column: span 4 / span 4;
    }

    :host([col-span-sm='5']) {
        grid-column: span 5 / span 5;
    }

    :host([col-span-sm='6']) {
        grid-column: span 6 / span 6;
    }

    :host([col-span-sm='7']) {
        grid-column: span 7 / span 7;
    }

    :host([col-span-sm='8']) {
        grid-column: span 8 / span 8;
    }

    :host([col-span-sm='9']) {
        grid-column: span 9 / span 9;
    }

    :host([col-span-sm='10']) {
        grid-column: span 10 / span 10;
    }

    :host([col-span-sm='11']) {
        grid-column: span 11 / span 11;
    }

    :host([col-span-sm='12']) {
        grid-column: span 12 / span 12;
    }
  }
  @media (min-width: 768px) {
    :host([col-span-md='1']) {
        grid-column: span 1 / span 1;
    }

    :host([col-span-md='2']) {
        grid-column: span 2 / span 2;
    }

    :host([col-span-md='3']) {
        grid-column: span 3 / span 3;
    }

    :host([col-span-md='4']) {
        grid-column: span 4 / span 4;
    }

    :host([col-span-md='5']) {
        grid-column: span 5 / span 5;
    }

    :host([col-span-md='6']) {
        grid-column: span 6 / span 6;
    }

    :host([col-span-md='7']) {
        grid-column: span 7 / span 7;
    }

    :host([col-span-md='8']) {
        grid-column: span 8 / span 8;
    }

    :host([col-span-md='9']) {
        grid-column: span 9 / span 9;
    }

    :host([col-span-md='10']) {
        grid-column: span 10 / span 10;
    }

    :host([col-span-md='11']) {
        grid-column: span 11 / span 11;
    }

    :host([col-span-md='12']) {
        grid-column: span 12 / span 12;
    }
  }
  :host([border-gap-size="small"]) {
    margin-bottom: 1rem;
}
  :host([border-gap-size="medium"]) {
    margin-bottom: 2.5rem;
}
  :host([border-gap-size="large"]) {
    margin-bottom: 4rem;
}
  @media (min-width: 1024px) {
    :host([col-span-lg='1']) {
        grid-column: span 1 / span 1;
    }

    :host([col-span-lg='2']) {
        grid-column: span 2 / span 2;
    }

    :host([col-span-lg='3']) {
        grid-column: span 3 / span 3;
    }

    :host([col-span-lg='4']) {
        grid-column: span 4 / span 4;
    }

    :host([col-span-lg='5']) {
        grid-column: span 5 / span 5;
    }

    :host([col-span-lg='6']) {
        grid-column: span 6 / span 6;
    }

    :host([col-span-lg='7']) {
        grid-column: span 7 / span 7;
    }

    :host([col-span-lg='8']) {
        grid-column: span 8 / span 8;
    }

    :host([col-span-lg='9']) {
        grid-column: span 9 / span 9;
    }

    :host([col-span-lg='10']) {
        grid-column: span 10 / span 10;
    }

    :host([col-span-lg='11']) {
        grid-column: span 11 / span 11;
    }

    :host([col-span-lg='12']) {
        grid-column: span 12 / span 12;
    }
  }
  @media (min-width: 1280px) {
    :host([col-span-xl='1']) {
        grid-column: span 1 / span 1;
    }

    :host([col-span-xl='2']) {
        grid-column: span 2 / span 2;
    }

    :host([col-span-xl='3']) {
        grid-column: span 3 / span 3;
    }

    :host([col-span-xl='4']) {
        grid-column: span 4 / span 4;
    }

    :host([col-span-xl='5']) {
        grid-column: span 5 / span 5;
    }

    :host([col-span-xl='6']) {
        grid-column: span 6 / span 6;
    }

    :host([col-span-xl='7']) {
        grid-column: span 7 / span 7;
    }

    :host([col-span-xl='8']) {
        grid-column: span 8 / span 8;
    }

    :host([col-span-xl='9']) {
        grid-column: span 9 / span 9;
    }

    :host([col-span-xl='10']) {
        grid-column: span 10 / span 10;
    }

    :host([col-span-xl='11']) {
        grid-column: span 11 / span 11;
    }

    :host([col-span-xl='12']) {
        grid-column: span 12 / span 12;
    }
    :host([border-gap-size="small"]), :host([border-gap-size="medium"]), :host([border-gap-size="large"]) {
        margin-bottom: 0px;
    }
    :host([has-left-divider]) {
      border-left: 1px solid var(--neutral-grayMedium) !important;
    }

    :host([has-left-divider][border-gap-size="small"]) {
        padding-left: 1rem;
    }
    :host([has-left-divider][border-gap-size="medium"]) {
        padding-left: 2.5rem;
    }
    :host([has-left-divider][border-gap-size="large"]) {
        padding-left: 4rem;
    }

    :host([has-right-divider][border-gap-size="small"]) {
        padding-right: 1rem;
    }
    :host([has-right-divider][border-gap-size="medium"]) {
        padding-right: 2.5rem;
    }
    :host([has-right-divider][border-gap-size="large"]) {
        padding-right: 4rem;
    }
  }
  @media (min-width: 1440px) {
    :host([col-span-xxl='1']) {
        grid-column: span 1 / span 1;
    }

    :host([col-span-xxl='2']) {
        grid-column: span 2 / span 2;
    }

    :host([col-span-xxl='3']) {
        grid-column: span 3 / span 3;
    }

    :host([col-span-xxl='4']) {
        grid-column: span 4 / span 4;
    }

    :host([col-span-xxl='5']) {
        grid-column: span 5 / span 5;
    }

    :host([col-span-xxl='6']) {
        grid-column: span 6 / span 6;
    }

    :host([col-span-xxl='7']) {
        grid-column: span 7 / span 7;
    }

    :host([col-span-xxl='8']) {
        grid-column: span 8 / span 8;
    }

    :host([col-span-xxl='9']) {
        grid-column: span 9 / span 9;
    }

    :host([col-span-xxl='10']) {
        grid-column: span 10 / span 10;
    }

    :host([col-span-xxl='11']) {
        grid-column: span 11 / span 11;
    }

    :host([col-span-xxl='12']) {
        grid-column: span 12 / span 12;
    }
  }
  :host([content-align='middle']) {
    justify-content: center;
}
  :host([content-align='bottom']) {
    justify-content: flex-end;
}
`;