
import { css } from 'lit';
export default css`
/* Apply standardized box sizing to the component. */
:host {
  box-sizing: border-box;
}
:host *,
:host *::before,
:host *::after {
  box-sizing: inherit;
}
[hidden] {
  display: none !important;
}
/* Apply component specific CSS */
.accordion-button {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  border-width: 0px;
  padding-top: 0px;
  padding-bottom: 0px;
  padding-left: 2rem;
  padding-right: 0px;
  font-family: Hind, Helvetica, Arial, sans-serif;
  font-size: var(--fs-2xl);
  line-height: var(--lh-2xl);
  font-weight: var(--fw-medium)
}

.accordion-button.mobile {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
  padding-left: 1rem !important;
  padding-right: 0px !important;
  font-size: var(--fs-base) !important;
  line-height: 1.25rem !important
}

.accordion-button.active {
  background-color: var(--demo-darkBlue);
  color: var(--neutral-white)
}

.accordion-button.inactive {
  background-color: var(--neutral-white);
  color: var(--demo-darkBlue)
}

.accordion-button.clean {
  border-bottom-width: 1px;
  border-style: solid;
  border-color: var(--demo-lightGray)
}

.accordion-button.clean.active {
  background-color: var(--neutral-white) !important;
  color: var(--demo-darkBlue) !important
}

.accordion-button.clean.inactive {
  color: var(--demo-darkBlue)
}

.accordion-content {
  padding-left: 2rem;
  padding-right: 2rem;
  padding-top: 1rem;
  color: var(--demo-darkGray)
}

.accordion-content.mobile {
  padding-left: 1rem;
  padding-right: 1rem
}

.accordion-label {
  padding-top: 1rem;
  padding-bottom: 1rem;
  text-align: left;
  max-width: 75%
}

.accordion-label.mobile {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem
}

.accordion-heading {
  margin: 0px;
  display: flex;
  min-height: 4rem
}

.accordion-icon {
  display: flex;
  height: 100%;
  width: 5rem;
  align-items: center;
  justify-content: center
}

.accordion-icon.clean {
  background-color: var(--neutral-white) !important;
  color: var(--demo-darkBlue) !important
}

.accordion-icon.active {
  background-color: var(--demo-mediumBlue);
  color: var(--neutral-white)
}

.accordion-icon.inactive {
  background-color: var(--demo-lightBlue);
  color: var(--demo-darkBlue)
}

.accordion-panel {
  margin-bottom: 1.5rem;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  border-radius: 0.375rem;
  border-width: 1px;
  border-style: solid;
  border-color: var(--demo-lightGray)
}

.accordion-panel.clean {
  border-width: 0px
}
`;