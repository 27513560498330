
import { css } from 'lit';
export default css`
/* Apply standardized box sizing to the component. */
:host {
  box-sizing: border-box;
}
:host *,
:host *::before,
:host *::after {
  box-sizing: inherit;
}
[hidden] {
  display: none !important;
}
/* Apply component specific CSS */
:host {
  /* flex and border-radius constrain the actual boundaries of the element,
   preventing literal edge cases where a user can click on the edge or corner of the button and default states like disabled */
  margin-top: 2rem;
  margin-bottom: 2rem;
  display: block;
  width: 100%;
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}

.btn[aria-disabled='true'] {
  pointer-events: none !important;
  cursor: not-allowed !important;
  background-color: var(--demo-lightGray) !important;
  color: var(--demo-darkGray) !important;
}

.btn {
  display: inline-flex;
  cursor: pointer;
  align-content: center;
  align-items: center;
  border-radius: 0.75rem;
  border-width: 0px;
  border-style: solid;
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
  padding-left: 1rem;
  padding-right: 1rem;
  font-family: Montserrat, Helvetica, Arial, sans-serif;
  line-height: 1.25;
  text-decoration: none;
  --tw-shadow: 0px 2px 5px 0px #00000026;
  --tw-shadow-colored: 0px 2px 5px 0px var(--tw-shadow-color);
  box-shadow: 0 0 #0000, 0 0 #0000, var(--tw-shadow);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  transition-property: background-color, border-color, color, fill, stroke;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 300ms;
}

.btn:hover {
  text-decoration: underline;
}

.btn:focus {
  text-decoration: underline;
}

.btn-mychart {
  width: 100%;
  background-color: var(--brand-blue);
  color: var(--neutral-white);
}

.btn-mychart:hover {
  background-color: var(--brand-blueDark);
}

.btn-mychart:focus {
  background-color: var(--brand-blueDark);
}

.btn-mychart-text {
  margin-left: 1rem;
  display: inline-flex;
  flex-direction: column;
  align-self: flex-start;
}

.btn-mychart-chevron {
  margin-left: 0.75rem;
  margin-top: 0.125rem;
  align-self: flex-start;
}

.btn-mychart-icon {
  height: var(--spacing-18);
  width: var(--spacing-18);
  fill: var(--neutral-white);
}

.btn-mychart-text--heading {
  display: inline-flex;
  justify-content: space-between;
  font-family: Montserrat, Helvetica, Arial, sans-serif;
  font-size: var(--fs-base);
  line-height: var(--lh-base);
  font-weight: var(--fw-bold);
}

.btn-mychart-text--body {
  margin-top: 0.75rem;
  font-family: Hind, Helvetica, Arial, sans-serif;
  font-size: var(--fs-base);
  line-height: var(--lh-base);
  font-weight: var(--fw-normal);
}
`;