
import { css } from 'lit';
export default css`
/* Apply standardized box sizing to the component. */
:host {
  box-sizing: border-box;
}
:host *,
:host *::before,
:host *::after {
  box-sizing: inherit;
}
[hidden] {
  display: none !important;
}
/* Apply component specific CSS */
:host {
  display: inline-block;
  cursor: pointer;
}

:host([disabled]) {
  cursor: not-allowed;
}

.tab {
  pointer-events: none;
  margin-bottom: 0.125rem;
  display: block;
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
  white-space: nowrap;
  border-radius: 0.25rem;
  border-bottom-width: 2px;
  border-color: var(--demo-darkGray);
  background-repeat: no-repeat;
  padding-top: 2rem;
  padding-bottom: 2rem;
  padding-left: 1.5rem;
  padding-right: 9rem;
  font-family: Hind, Helvetica, Arial, sans-serif;
  font-size: var(--fs-xl);
  line-height: var(--lh-xl);
  font-weight: var(--fw-medium);
  color: var(--demo-darkGray);
}

@media (min-width: 1024px) {

  .tab {
    font-size: var(--fs-2xl);
    line-height: var(--lh-2xl);
  }
}

.tab {
  background-image: url("data:image/svg+xml,%3Csvg width='12' height='22' viewBox='0 0 12 22' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M11.6036 10.3964L1.4449 0.237629C1.10859 -0.0871735 0.572681 -0.0778466 0.247879 0.258463C-0.0689857 0.586538 -0.0689856 1.10663 0.247879 1.43465L9.8081 10.9949L0.247879 20.5551C-0.0826265 20.8857 -0.0826265 21.4216 0.247879 21.7521C0.578485 22.0826 1.11435 22.0826 1.4449 21.7521L11.6036 11.5934C11.9341 11.2628 11.9341 10.7269 11.6036 10.3964Z' fill='%23363739'/%3E%3C/svg%3E%0A");
  background-position: calc(100% - 24px) 50%;
}

.tab:hover:not(.tab--disabled) {
  color: var(--demo-lightGray);
}

.tab:focus {
  outline: 2px solid transparent;
  outline-offset: 2px;
}

.tab:focus:not(.tab--disabled), .tab:focus-visible:not(.tab--disabled) {
  background-color: var(--demo-lightBlue);
  color: var(--demo-darkBlue);
  --tw-shadow: inset 0 2px 4px 0 rgb(0 0 0 / 0.05);
  --tw-shadow-colored: inset 0 2px 4px 0 var(--tw-shadow-color);
  box-shadow: 0 0 #0000, 0 0 #0000, var(--tw-shadow);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.tab.tab--active:not(.tab--disabled) {
  background-color: var(--demo-lightGray);
  color: var(--demo-darkBlue);
  background-image: url("data:image/svg+xml,%3Csvg width='12' height='22' viewBox='0 0 12 22' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M11.6036 10.3964L1.4449 0.237629C1.10859 -0.0871735 0.572681 -0.0778466 0.247879 0.258463C-0.0689857 0.586538 -0.0689856 1.10663 0.247879 1.43465L9.8081 10.9949L0.247879 20.5551C-0.0826265 20.8857 -0.0826265 21.4216 0.247879 21.7521C0.578485 22.0826 1.11435 22.0826 1.4449 21.7521L11.6036 11.5934C11.9341 11.2628 11.9341 10.7269 11.6036 10.3964Z' fill='%23056CB6'/%3E%3C/svg%3E%0A");
}

.tab.tab--disabled {
  cursor: not-allowed;
  opacity: 0.5;
}
`;