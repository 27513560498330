import { CSSResultGroup } from 'lit';
import { customElement } from 'lit/decorators.js';
import componentStyles from './ochsner-map.css.lit';

import { OutlineGoogleMap } from '../../base/outline-google-map/outline-google-map';

/**
 * The Ochsner Map.
 */
@customElement('ochsner-map')
export class OchsnerMap extends OutlineGoogleMap {
  // Set the CSS styles for the component.
  static styles: CSSResultGroup = [componentStyles];

  _mapLoaded = false;
  _tabShown = false;

  // Overriding from outline-google-map to call initGMap after tab is opened.
  connectedCallback() {
    super.connectedCallback();

    window.addEventListener('ochsner-tab-show', () => {
      const parentPanel = this.closest('ochsner-tab-panel');
      if (parentPanel?.active) {
        this._tabShown = true;
        this.loadingChangedCallback();
      }
    });
  }

  // Overriding function from outline-google-map.
  // Prevent initGMap running before the tab is opened.
  mapApiLoaded() {
    this._mapLoaded = true;
    this.loadingChangedCallback();
  }

  loadingChangedCallback(): void {
    if (this._mapLoaded && this._tabShown) {
      this.updateAttributesFromUrl();
      this.initGMap();
    }
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'ochsner-map': OchsnerMap;
  }
}
