
import { css } from 'lit';
export default css`
/* Apply standardized box sizing to the component. */
:host {
  box-sizing: border-box;
}
:host *,
:host *::before,
:host *::after {
  box-sizing: inherit;
}
[hidden] {
  display: none !important;
}
/* Apply component specific CSS */
:host {

  margin-bottom: 1.5rem;

  display: block;

  font-family: Montserrat, Helvetica, Arial, sans-serif
}

/* Devices with mouse support. */

@media (any-hover: hover) and (any-pointer: fine) {
  :host {

    cursor: pointer
  }
}

.card--content {

  display: flex;

  height: 100%;

  cursor: pointer;

  flex-direction: row;

  flex-wrap: wrap;

  align-self: stretch;

  border-radius: 0.5rem;

  background-color: var(--neutral-white);

  padding-bottom: 1rem;

  font-size: var(--fs-lg);

  line-height: var(--lh-lg);

  --tw-shadow: 0px 2px 5px 0px #00000026;

  --tw-shadow-colored: 0px 2px 5px 0px var(--tw-shadow-color);

  box-shadow: 0 0 #0000, 0 0 #0000, var(--tw-shadow);

  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
}

.card--content:focus-within {

  background-color: var(--brand-blueLight-10);

  --tw-shadow: 0 0 0 1px rgb(33, 74, 222);

  --tw-shadow-colored: 0 0 0 1px var(--tw-shadow-color);

  box-shadow: 0 0 #0000, 0 0 #0000, var(--tw-shadow);

  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
}

.card--content .card--content-area {

  width: 100%
}

.card--content .card--content-area {

  padding-left: 1rem;

  padding-right: 1rem
}

.card--content h2 {

  margin: 0px
}

.card--content h2 {

  margin-bottom: 0.5rem
}

.card--content h2 {

  padding-top: 0.75rem
}

.card--content h2 {

  font-size: var(--fs-lg);

  line-height: var(--lh-lg)
}

.card--content h2 {

  color: var(--brand-blue)
}

.card--content outline-image {

  width: 100%
}

.card--content outline-image {

  object-fit: cover
}

.card--content ::slotted(img) {

  max-height: var(--spacing-56)
}

.card--content ::slotted(img) {

  width: 100%
}

.card--content ::slotted(img) {

  border-top-left-radius: 0.5rem;

  border-top-right-radius: 0.5rem
}

.card--content ::slotted(img) {

  object-fit: cover
}

.card--content ::slotted(img) {

  padding-bottom: 0.5rem
}

.card--content a {

  display: flex
}

.card--content a {

  width: 100%
}

.card--content a {

  align-items: center
}

.card--content a {

  border-width: 0px
}

.card--content a {

  padding-top: 0.5rem
}

.card--content a {

  font-weight: var(--fw-semibold)
}

.card--content a {

  font-weight: var(--fw-semibold)
}

.card--content a {

  color: var(--ui-accent)
}

.card--content a {

  text-decoration: none
}

.card--content a:focus {

  --tw-shadow: 0 0 #0000;

  --tw-shadow-colored: 0 0 #0000;

  box-shadow: 0 0 #0000, 0 0 #0000, var(--tw-shadow);

  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
}

.card--content a:focus {

  outline: 2px solid transparent;

  outline-offset: 2px
}

.card--content outline-icon {

  padding-left: 0.25rem
}

.card--content-highlighted {

  background-color: var(--brand-blueDark);

  color: var(--neutral-white);
}

.card--content-highlighted .title {

  color: var(--neutral-white)
}

.card--content-highlighted a {

  align-items: center
}

.card--content-highlighted a {

  color: var(--neutral-white)
}
`;