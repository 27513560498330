import { html, TemplateResult, CSSResultGroup } from 'lit';
import { customElement } from 'lit/decorators.js';
import { unsafeSVG } from 'lit/directives/unsafe-svg.js';
import { OutlineButton } from '../../base/outline-button/outline-button';
import componentStyles from './ochsner-mychart-button.css.lit';
import icon from './svgs/mychart_login_icon.svg';
/**
 * The Ochsner Button component
 */

@customElement('ochsner-mychart-button')
export class OchsnerMychartButton extends OutlineButton {
  static styles: CSSResultGroup = [componentStyles];

  render(): TemplateResult {
    return html` 
      <a
        class="btn btn-mychart"
        href="epicmychart://openlogin"
      >
        <div class="btn-mychart-icon">${unsafeSVG(icon)}</div>
        <div class="btn-mychart-text">
          <span class="btn-mychart-text--heading">
            Log in to your account
            <outline-icon 
              class="btn-mychart-chevron" 
              name="icon-chevron" 
              mobileSize = '16px' 
              size = '16px'></outline-icon>
          </span>
          <span class="btn-mychart-text--body">See your visits, test results, messages and more</span>
        </div>
      </a>`;
  }
}
